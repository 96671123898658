import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import { useColorMode } from "@chakra-ui/react";

const Chart = ({ data }) => {
  const chartRef = useRef(null);
  const { colorMode } = useColorMode();

  useEffect(() => {
    if (!data) {
      return;
    }

    const { today, last7day } = data;

    if (!today || !last7day) {
      return;
    }

    const labels = Object.keys(today);
    const todayData = Object.values(today);
    const last7dayData = Object.values(last7day);

    const options = {
      chart: {
        type: "bar",
        height: 300,
        toolbar: {
          show: false,
        },
        background: colorMode === "dark" ? "#2D3748" : "#EDF2F7",
      },
      series: [
        {
          name: "Today",
          data: todayData,
        },
        {
          name: "Last 7 Days",
          data: last7dayData,
        },
      ],
      xaxis: {
        categories: labels,
      },
      colors: ["#3182CE", "#E53E3E"],
      fill: {
        opacity: 0.8,
      },
      theme: {
        mode: colorMode === "dark" ? "dark" : "light",
      },
    };

    const chart = new ApexCharts(chartRef.current, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [data, colorMode]);

  return <div ref={chartRef}></div>;
};

export default Chart;
