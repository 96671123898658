import {
  Badge,
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Slider,
  SliderThumb,
  SliderTrack,
  SliderFilledTrack,
  Text,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import http from "../utils/Http";
// import { socket } from "../utils/Socket";

const AttackHub = () => {
  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const [selectedGroup, setSelectedGroup] = useState("");
  const [services, setServices] = useState([]);
  const [payloads, setPayloads] = useState([]);
  const [additionalPayloads, setAdditionalPayloads] = useState([]);
  const [createAttack, setCreateAttack] = useState({}); // object for create new attack
  const [currentAttackId, setCurrentAttackId] = useState("");
  const [isConnected, setIsConnected] = useState(true);
  const [attackLogs, setAttackLogs] = useState([]);

  const [maxTotal, setMaxTotal] = useState(0);
  const [t] = useTranslation("global");

  useEffect(() => {
    fetchServices();
  }, []);

  useEffect(() => {
    let intervalLog = null;

    if (currentAttackId) {
      intervalLog = setInterval(() => {
        fetchAttackLogs(currentAttackId);
      }, 2500);
    }

    return () => {
      clearInterval(intervalLog);
    };
  }, [currentAttackId]);

  // useEffect(() => {
  //   function onConnect() {
  //     setIsConnected(true);
  //   }

  //   function onDisconnect() {
  //     setIsConnected(false);
  //   }

  //   socket.on("connect", onConnect);
  //   socket.on("disconnect", onDisconnect);

  //   return () => {
  //     socket.off("connect", onConnect);
  //     socket.off("disconnect", onDisconnect);
  //   };
  // }, []);

  const fetchServices = async () => {
    const res = await http.get("/service");
    setServices(res.data.data.results);
  };

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
    const serviceList = [...services];
    const service = serviceList.find(
      (s) => parseInt(s.id) === parseInt(event.target.value)
    );

    if (service) {
      console.log(JSON.parse(service.payload).form);
      setPayloads(JSON.parse(service.payload).form);
      setMaxTotal(0);
      setCreateAttack({});
      Array.from(document.querySelectorAll(".payload-input")).forEach(
        (input) => (input.value = "")
      );
    } else {
      setPayloads([]);
    }
  };

  const checkAdditionalReason = (payload, reason) => {
    const payloadList = [...payloads];
    const selectedPayload = payloadList.find((p) => p.name === payload);
    if (selectedPayload.additional) {
      const additionalPayload = selectedPayload.additional[reason];
      setAdditionalPayloads(additionalPayload || []);
      console.log(additionalPayload);
    }
  };

  const handleAttack = async () => {
    const serviceList = [...services];
    const service = serviceList.find(
      (s) => parseInt(s.id) === parseInt(selectedGroup)
    );

    try {
      const res = await http.post("/order", {
        ...createAttack,
        action: service.action,
        group: service.group,
      });

      toast({
        title: "Attack Created!",
        description: "Your attack successfully created.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
  
      setCurrentAttackId(res.data.data.id);
    } catch (error) {
      toast({
        title: error.response.data.errors,
        description: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const fetchAttackLogs = async (orderId) => {
    const res = await http.get(`/order/id/${orderId}`);
    setAttackLogs(res.data.data.result.orderReport);
  };

  const getMaxOrder = async () => {
    try {
      if (createAttack.username || createAttack.link) {
        const response = await http.post("/order/max", {
          group:
            selectedGroup > 5
              ? services[selectedGroup - 3].group
              : services[selectedGroup - 1].group,
          target: createAttack.username || createAttack.link,
        });
        setMaxTotal(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} / {t("page_attack_pannel.title")}
        </title>
      </Helmet>
      <Container maxW="container.xl" h="full" p={0}>
        <Heading py={8} px={isMobile ? "4" : "0"} color={"gray.400"}>
          {t("page_attack_pannel.title")}
        </Heading>
        <Flex h="100vh" direction={isMobile ? "column" : "row"}>
          <VStack
            w={isMobile ? "full" : "50%"}
            spacing={10}
            py={isMobile ? "4" : "0"}
            px={isMobile ? "4" : "0"}
            pr={isMobile ? "4" : "8"}
            alignItems="flex-start"
          >
            <Box bg={"gray.700"} px={4} py={4} w={"full"} borderRadius={4}>
              <Heading as={"h4"} color={"gray.400"} size={"sm"} mb={4}>
                {t("page_attack_pannel.attack_card.title")}
              </Heading>
              <Box>
                <form>
                  <FormControl>
                    <FormLabel>Platform</FormLabel>
                    <Select
                      onChange={handleGroupChange}
                      value={selectedGroup}
                      placeholder={t(
                        "page_attack_pannel.attack_card.form.platform.default_input_value"
                      )}
                    >
                      {services.map((service, index) => (
                        <option key={index} value={service.id}>
                          {service.group} | {service.action.split("_")[0]}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  {/* <FormControl mt={2}>
                    <FormLabel>Action</FormLabel>
                    <Select
                      id="action"
                      onChange={handleActionChange}
                      value={selectedAction}
                    ></Select>
                  </FormControl> */}

                  {payloads.length > 0 ? (
                    <>
                      {payloads.map((payload, index) => (
                        <>
                          {payload.type === "option" ? (
                            <>
                              <FormControl mt={2}>
                                <FormLabel>{payload.name}</FormLabel>
                                <Select
                                  placeholder="select reason"
                                  onChange={(event) => {
                                    checkAdditionalReason(
                                      payload.name,
                                      event.target.value
                                    );
                                    setCreateAttack({
                                      ...createAttack,
                                      [payload.name]: event.target.value,
                                    });
                                  }}
                                  value={createAttack[payload.name]}
                                >
                                  {payload.choices.map((choice, index) => (
                                    <option key={index} value={choice}>
                                      {choice}
                                    </option>
                                  ))}
                                </Select>
                              </FormControl>

                              {additionalPayloads.length > 0 ? (
                                <>
                                  {additionalPayloads.map(
                                    (additionalPayload, index) => (
                                      <>
                                        {additionalPayload.type === "option" ? (
                                          <FormControl mt={2}>
                                            <FormLabel>
                                              {additionalPayload.name}
                                            </FormLabel>
                                            <Select
                                              onChange={(event) => {
                                                setCreateAttack({
                                                  ...createAttack,
                                                  [additionalPayload.name]:
                                                    event.target.value,
                                                });
                                              }}
                                              value={
                                                createAttack[
                                                  additionalPayload.name
                                                ]
                                              }
                                              placeholder="select additional reason"
                                            >
                                              {additionalPayload.choices.map(
                                                (choice, index) => (
                                                  <option
                                                    key={index}
                                                    value={choice}
                                                  >
                                                    {choice}
                                                  </option>
                                                )
                                              )}
                                            </Select>
                                          </FormControl>
                                        ) : (
                                          <FormControl mt={2} key={index}>
                                            <FormLabel>
                                              {additionalPayload.name}
                                            </FormLabel>
                                            <Input
                                              type={additionalPayload.type}
                                              placeholder={
                                                additionalPayload.placeholder
                                              }
                                              onChange={(event) =>
                                                setCreateAttack({
                                                  ...createAttack,
                                                  [additionalPayload.name]:
                                                    event.target.value,
                                                })
                                              }
                                            />
                                          </FormControl>
                                        )}
                                      </>
                                    )
                                  )}
                                </>
                              ) : null}
                            </>
                          ) : (
                            <>
                              {payload.name === "total" ? (
                                <>
                                  {maxTotal > 0 && (
                                    <FormControl mt={2}>
                                      <FormLabel mt={4}>
                                        total: {createAttack.total}
                                      </FormLabel>
                                      <Slider
                                        defaultValue={1}
                                        min={1}
                                        max={maxTotal}
                                        onChange={(value) =>
                                          setCreateAttack((prevState) => ({
                                            ...prevState,
                                            total: value,
                                          }))
                                        }
                                      >
                                        <SliderTrack>
                                          <SliderFilledTrack />
                                        </SliderTrack>
                                        <SliderThumb />
                                      </Slider>
                                    </FormControl>
                                  )}
                                </>
                              ) : payload.name === "username" ||
                                payload.name === "link" ? (
                                <FormControl mt={2} key={index}>
                                  <FormLabel>{payload.name}</FormLabel>
                                  <Input
                                    type={payload.type}
                                    placeholder={payload.placeholder}
                                    className="payload-input"
                                    onFocus={() => {
                                      setMaxTotal(0);
                                      setCreateAttack((curr) => ({
                                        username: curr.username,
                                        reason: "",
                                      }));
                                    }}
                                    onBlur={() => getMaxOrder()}
                                    onChange={(event) =>
                                      setCreateAttack((prevState) => ({
                                        ...prevState,
                                        [payload.name]: event.target.value,
                                      }))
                                    }
                                  />
                                </FormControl>
                              ) : (
                                <FormControl mt={2} key={index}>
                                  <FormLabel>{payload.name}</FormLabel>
                                  <Input
                                    type={payload.type}
                                    placeholder={payload.placeholder}
                                    onChange={(event) =>
                                      setCreateAttack((prevState) => ({
                                        ...prevState,
                                        [payload.name]: event.target.value,
                                      }))
                                    }
                                  />
                                </FormControl>
                              )}
                            </>
                          )}
                        </>
                      ))}
                    </>
                  ) : null}

                  <Button
                    colorScheme="teal"
                    my={4}
                    w={"full"}
                    onClick={() => handleAttack()}
                  >
                    Submit
                  </Button>
                </form>
              </Box>
            </Box>
          </VStack>
          <VStack
            w="full"
            h="full"
            spacing={10}
            px={isMobile ? "4" : "0"}
            alignItems="flex-start"
          >
            <TableContainer bg="gray.700" w="full" borderRadius={4}>
              <Flex>
                <Heading as="h4" color="gray.400" size="sm" py={4} px={4}>
                  {t("page_attack_pannel.attack_logs_card.title")}
                </Heading>
                <Box ml="auto" py={4} px={4}>
                  {isConnected ? (
                    <Badge colorScheme="green">
                      {t(
                        "page_attack_pannel.attack_logs_card.status_connected"
                      )}
                    </Badge>
                  ) : (
                    <Badge colorScheme="red">
                      {t(
                        "page_attack_pannel.attack_logs_card.status_disconnected"
                      )}
                    </Badge>
                  )}
                </Box>
              </Flex>
              <TableContainer>
                <Table variant="striped" colorScheme="teal">
                  <TableCaption>
                    {t("page_attack_pannel.attack_logs_card.table.caption")}
                  </TableCaption>
                  <Thead>
                    <Tr>
                      <Th>
                        {t(
                          "page_attack_pannel.attack_logs_card.table.attack_id"
                        )}
                      </Th>
                      <Th>
                        {t(
                          "page_attack_pannel.attack_logs_card.table.identifier"
                        )}
                      </Th>
                      <Th>
                        {t("page_attack_pannel.attack_logs_card.table.service")}
                      </Th>
                      <Th>
                        {t("page_attack_pannel.attack_logs_card.table.target")}
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {attackLogs.map((log, index) => (
                      <>
                        <Tr key={index}>
                          <Td>{log.id}</Td>
                          <Td>{log.identifier}</Td>
                          <Td>{log.service_name}</Td>
                          <Td>{log.target}</Td>
                        </Tr>
                      </>
                    ))}
                  </Tbody>
                  <Tfoot>
                    <Tr>
                      <Th>
                        {t(
                          "page_attack_pannel.attack_logs_card.table.attack_id"
                        )}
                      </Th>
                      <Th>
                        {t(
                          "page_attack_pannel.attack_logs_card.table.identifier"
                        )}
                      </Th>
                      <Th>
                        {t("page_attack_pannel.attack_logs_card.table.service")}
                      </Th>
                      <Th>
                        {t("page_attack_pannel.attack_logs_card.table.target")}
                      </Th>
                    </Tr>
                  </Tfoot>
                </Table>
              </TableContainer>
            </TableContainer>
          </VStack>
        </Flex>
      </Container>
    </>
  );
};

export default AttackHub;
