import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Button,
	Textarea
} from "@chakra-ui/react";

const ErrorModal = ({ isOpen, onClose, msg }) => {
	return (
		<Modal onClose={onClose} isOpen={isOpen} isCentered>
		  <ModalOverlay />
		  <ModalContent>
		    <ModalHeader>Error Message</ModalHeader>
		    <ModalCloseButton />
		    <ModalBody>
		      <Textarea isReadOnly={true} size="md">{msg}</Textarea>
		    </ModalBody>
		    <ModalFooter>
		      <Button onClick={onClose}>Close</Button>
		    </ModalFooter>
		  </ModalContent>
		</Modal>
  )
}

export default ErrorModal;