import React, { useState } from "react";
import {
  Box,
  Flex,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
  Text,
  Container,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import {
  FaUserAlt,
  FaHome,
  FaWifi,
  FaListAlt,
  FaTv,
  FaBars,
} from "react-icons/fa";
import { MdManageAccounts } from "react-icons/md";
import bottifyLogo from "../assets/logo/bottify.png";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import cookieCutter from "cookie-cutter";
import { ROLE_ADMIN } from "../utils/Const";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export default function Nav() {
  const history = useHistory();
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onToggle } = useDisclosure();
  const [language, setLanguage] = useState('en')
  const [t] = useTranslation('global')

  useEffect(() => {
    if (colorMode !== "dark") {
      toggleColorMode();
    }
  }, [colorMode, toggleColorMode]);

  useEffect(() => {
    handleChangeLanguage(language)
  }, [language])

  const handleChangeLanguage = (language) => {
    i18next.changeLanguage(language)
  }

  const handleLogout = () => {
    cookieCutter.set("access_token", "");
    cookieCutter.set("fullname", "");
    cookieCutter.set("role", "");
    history.push(`/login`);
  };

  return (
    <Box bg="gray.700" px={4}>
      <Container maxW="container.xl" p={0}>
        <Flex
          h={16}
          alignItems="center"
          justifyContent="space-between"
          padding={2}
        >
          <Box>
            <img
              src={bottifyLogo}
              style={{ width: "50%", maxWidth: "200px" }}
              alt="logo"
            />
          </Box>

          <Flex alignItems="center">
            <Stack
              direction="row"
              spacing={7}
              display={{ base: "none", md: "flex" }}
            >
              <Box>
                <Button onClick={() => language === 'en' ? setLanguage('id') : setLanguage('en')}>{language === 'en' ? 'id' : 'en'}</Button>
              </Box>
              <Menu>
                <MenuButton
                  as={Button}
                  rounded="full"
                  variant="link"
                  cursor="pointer"
                  minW={0}
                >
                  <Flex justifyContent="between" alignItems="center">
                    <FaUserAlt style={{ marginRight: "10px" }} />
                    <Text>{cookieCutter.get("fullname")}</Text>
                  </Flex>
                </MenuButton>
                <MenuList alignItems="center">
                  <br />
                  <Center>
                    <Avatar size="2xl" src={<FaUserAlt />} />
                  </Center>
                  <br />
                  <Center>
                    <p>{cookieCutter.get("fullname")}</p>
                  </Center>
                  <br />
                  <MenuDivider />
                  <MenuItem as="button" onClick={handleLogout}>
                    {t('navbar.logout')}
                  </MenuItem>
                </MenuList>
              </Menu>
            </Stack>

            <IconButton
              icon={<FaBars />}
              size="md"
              variant="ghost"
              colorScheme="facebook"
              onClick={onToggle}
              display={{ base: "block", md: "none" }}
            />
          </Flex>
        </Flex>
        <hr />
        <Flex
          alignItems="center"
          justifyContent="space-around"
          padding={2}
          direction={{ base: "column", md: "row" }}
          display={{ base: isOpen ? "flex" : "none", md: "flex" }}
        >
          <Button
            size="md"
            variant="ghost"
            colorScheme="facebook"
            leftIcon={<FaHome />}
            onClick={() => history.push("/dashboard")}
            mb={{ base: 2, md: 0 }}
          >
            {t('navbar.dashboard')}
          </Button>
          <Button
            size="md"
            variant="ghost"
            colorScheme="facebook"
            leftIcon={<FaWifi />}
            onClick={() => history.push("/attack-panel")}
            mb={{ base: 2, md: 0 }}
          >
            {t('navbar.attack_pannel')}
          </Button>
          <Button
            size="md"
            variant="ghost"
            colorScheme="facebook"
            leftIcon={<FaListAlt />}
            onClick={() => history.push("/attack-history")}
            mb={{ base: 2, md: 0 }}
          >
            {t('navbar.attack_history')}
          </Button>
          <Button
            size="md"
            variant="ghost"
            colorScheme="facebook"
            leftIcon={<FaTv />}
            onClick={() => history.push("/monitoring")}
            mb={{ base: 2, md: 0 }}
          >
            {t('navbar.monitoring')}
          </Button>
          {cookieCutter.get('role') === ROLE_ADMIN && <Button
            size="md"
            variant="ghost"
            colorScheme="facebook"
            leftIcon={<MdManageAccounts size={20} />}
            onClick={() => history.push("/manage-account")}
            mb={{ base: 2, md: 0 }}
          >
            {t('navbar.manage_account')}
          </Button>}
        </Flex>
      </Container>
    </Box>
  );
}
