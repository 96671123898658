import { useEffect, useRef, useState } from "react";
import { FaAngleLeft, FaAngleRight, FaSearch, FaChevronDown } from "react-icons/fa";
import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  Card,
  CardBody,
  Button,
  useToast,
  Badge,
  Spinner,
  Skeleton,
  Select,
  useBreakpointValue,
  Input,
  Grid,
  GridItem,
  SimpleGrid,
  Wrap,
  WrapItem,
  Menu,
  MenuButton,
  MenuList,
  MenuItem
} from "@chakra-ui/react";
import http from "../utils/Http";
import { dateTimeFormat } from "../utils/Helper";
import Helmet from "react-helmet";
import cookieCutter from "cookie-cutter";
import { ROLE_ADMIN } from "../utils/Const";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';
import ErrorModal from '../components/ErrorModal';

const listColorScheme = {
  background: "#275E61",
  background2: '#38B2AC'
}

const AttackHistory = () => {
  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const searchRef = useRef()
  const [services, setServices] = useState([]);
  const [histories, setHistories] = useState([])
  const [detailHistory, setDetailHistory] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [errorModalMsg, setErrorModalMsg] = useState('')
  const [t] = useTranslation('global')
  const [filter, setFilter] = useState({
    platform: '',
    time: '',
    status: '',
  })
  const [page, setPage] = useState({
    onPage: 1,
    totalData: 0,
    totalPage: 0
  })

  const fetchServices = async () => {
    const res = await http.get("/service");
    setServices(res.data.data.results);
  };

  const resetActiveList = () => {
    const attackHistoryAll = Array.from(document.querySelectorAll('.list-attack-history'))
    attackHistoryAll.forEach((attackHistory) => {
      attackHistory.style.background = listColorScheme.background2
    });
  }

  const getHistoryAttack = async (search='', filter=null) => {
    setIsLoading(true)

    try {
      let url = `/order/user?page=${page.onPage}`
      if(cookieCutter.get('role') === ROLE_ADMIN){
        url = `/order?page=${page.onPage}`
      }
      if(search){
        url = `${url}&search=${search}`
      }else if(filter){
        const params = new URLSearchParams(filter).toString()
        url = `${url}&${params}`
      }
      const response = await http.get(url)
      const totalPage = Math.ceil(response.data.data.total / 10)
      setHistories(response.data.data.results)
      setPage((curr) => ({
        onPage: totalPage < curr.onPage ? 1 : curr.onPage,
        totalData: response.data.data.total,
        totalPage: totalPage
      }))
    } catch (error) {
      toast({
        title: "something went wrong",
        status: 'error',
        isClosable: true,
      })
    }

    setIsLoading(false)
  }

  const handleNextPage = () => {
    if (page.onPage < page.totalPage){
      resetActiveList()
      setDetailHistory({})
      setPage((curr) => ({
        onPage: curr.onPage + 1,
        totalData: curr.totalData,
        totalPage: curr.totalPage
      }))
    }
  }

  const handlePrevPage = () => {
    if(page.onPage !== 1){
      resetActiveList()
      setDetailHistory({})
      setPage((curr) => ({
        onPage: curr.onPage - 1,
        totalData: curr.totalData,
        totalPage: curr.totalPage
      }))
    }
  }

  const handleSearchHistory = async (e) => {
    e.preventDefault()
    await getHistoryAttack(searchRef.current.value)
  }

  const getDetailHistory = async (history) => {
    const response = await http.get(`/order/id/${history.id}`)
    setDetailHistory(response.data.data.result)
  }

  const handleDetailHistory = async (history) => {
    setIsLoading(true)

    resetActiveList()

    const attackHistoryTarget = document.querySelector(`#list-attack-history-${history.id}`)
    attackHistoryTarget.style.background = listColorScheme.background

    try {
      getDetailHistory(history)  
    } catch (error) {
      toast({
        title: "something went wrong",
        status: 'error',
        isClosable: true,
      })
    }

    setIsLoading(false)
  }

  const handleRetryOrder = async (history) => {
    setIsLoading(true)
    try {
      const response = await http.get(`/order/${history.order_id}/retry`)
      if(response.status === 200){
        await handleDetailHistory(history)
      }
    } catch (error) {
      toast({
        title: "something went wrong",
        status: 'error',
        isClosable: true,
      })
    }
    setIsLoading(false)
  }

  const handleViewError = async (orderId) => {
    try {
      const response = await http.get(`/order/${orderId}/error`)
      if(response.status === 200) {
        setErrorModalMsg(response.data?.data?.error_msg || 'No error message yet')
        onOpen()
      }
    } catch (error) {
      toast({
        title: "something went wrong",
        status: 'error',
        isClosable: true,
      })
    }
  }

  const searchOrderReport = (e) => {
    const query = e.target.value
    let filterData = []
    

    filterData = detailHistory.orderReport.filter((item) => {
      return item.identifier.toLowerCase().includes(query) || 
      item.target.toLowerCase().includes(query) || 
      item.id.toString().includes(query)
    })
    
    if(filterData.length > 0){
      setDetailHistory((curr) => ({
        ...curr,
        isFilter: true,
        orderReportFilter: filterData
      }))
    }else{
      setDetailHistory((curr) => ({
        ...curr,
        isFilter: false,
        orderReportFilter: detailHistory.orderReport
      }))
    }
  }

  const filterOrderReport = async (e, params=null) => {
    let filter = {}
    let status = ''
    const date = new Date()

    const formatDate = (d) => {
      let month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear()

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    }

    if(e){
      status = e.target.innerText
    }else if(params){
      status = params
    }

    switch (status) {
      case t('page_dashboard.card.processing_attack.title'):
        filter.status = 'running'
        break;
      case t('page_dashboard.card.completed_attack.title'):
        filter.status = 'completed'
        break;
      case t('page_dashboard.card.retry_attack.title'):
        filter.status = 'stopped'
        break;
      case t('page_dashboard.card.today_attack.title'):
        filter.created_at = formatDate(date)
        break;
      case t('page_dashboard.card.weekly_attack.title'):
        const startDate = new Date(date)
        startDate.setDate(startDate.getDate() - 7)

        filter.created_at = {
          start_date: formatDate(startDate),
          end_date: formatDate(date)
        }
        break;
    }

    filter = JSON.stringify(filter)
    await getHistoryAttack(null, null, filter)
  }

  const handleStatus = (order_status) => {
    const statuses = [
      {
        status: 'queued',
        colorScheme: 'yellow'
      },
      {
        status: 'running',
        colorScheme: 'gray'
      },
      {
        status: 'completed',
        colorScheme: 'teal'
      },
      {
        status: 'stopped',
        colorScheme: 'red'
      },
    ]

    return statuses.map((data, idx) => {
      if(data.status === order_status){
        if(data.status === 'running'){
          return <div key={idx}>
            <Badge variant='subtle' colorScheme={data.colorScheme} mr="3" p={1} display="flex" justifyContent="center">
              <Spinner color={data.colorScheme} size="sm" mr="1" />
              {t('page_attack_history.detail_attack_card.status')} {data.status}
            </Badge>
          </div>
        }else{
          return <Badge variant='subtle' colorScheme={data.colorScheme} mr="3">status is {data.status}</Badge>
        }
      }else{
        return <></>
      }
    })
  }

  useEffect(() => {
    if(!Object.values(filter).every(value => value === '')){
      getHistoryAttack(searchRef.current.value, filter)  
    }else{
      getHistoryAttack(searchRef.current.value)
    }
  }, [page.onPage, filter])

  useEffect(() => {
    fetchServices()
  }, [])

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} / {t('page_attack_history.title')}</title>
      </Helmet>
      <Container maxW="container.xl" p={0}>
        <Heading py={8} px={isMobile ? "4" : "0"} color={"gray.400"}>
          {t('page_attack_history.title')}
        </Heading>
        <Flex h="100vh" direction={isMobile ? "column" : "row"}>
          <VStack w={isMobile ? "full" : "50%"} spacing={10} py={isMobile ? "4" : "0"} px={isMobile ? "4" : "0"} pr={isMobile ? "4" : "8"} alignItems="flex-start">
            <Box bg={"gray.700"}py={4} w={"full"} borderRadius={4}>
              <Box px={5} mb={4}>
                <Wrap justify={'space-between'}>
                  <WrapItem width={'35%'}>
                    <Select placeholder={'Platform'} onChange={(e) => setFilter((curr => ({...curr, platform: e.target.value})))}>
                      {Array.from(new Set(services.map((item) => item.group))).map((service) => (<option value={service}>{service}</option>))}
                    </Select>
                  </WrapItem>
                  <WrapItem width={'30%'}>
                    <Select placeholder={'Time'} onChange={(e) => setFilter((curr => ({...curr, time: e.target.value})))}>
                      <option value='all'>All</option>
                      <option value='24 hours'>24 hours</option>
                      <option value='7 day'>7 day</option>
                      <option value='30 day'>30 day</option>
                    </Select>
                  </WrapItem>
                  <WrapItem width={'30%'} onChange={(e) => setFilter((curr => ({...curr, status: e.target.value})))}>
                    <Select placeholder={'Status'}>
                      <option value='running'>Processing</option>
                      <option value='stopped'>Error</option>
                      <option value='completed'>Success</option>
                    </Select>
                  </WrapItem>
                </Wrap>
              </Box>
              <Box px={5}>
                <form onSubmit={(e) => handleSearchHistory(e)}>
                  <Wrap justify='space-between'>
                    <WrapItem width="80%">
                      <Input type="text" placeholder="search..." width="100%" ref={searchRef} />
                    </WrapItem>
                    <WrapItem>
                      <Button type="submit" colorScheme="teal" variant="ghost"><FaSearch/></Button>
                      {/* <Button type="button" colorScheme="teal" variant="ghost"><FaChevronDown/></Button> */}
                      {/* <Menu>
                        <MenuButton as={Button} colorScheme="teal" variant="ghost">
                          <FaChevronDown />
                        </MenuButton>
                        <MenuList>
                          <MenuItem onClick={(e) => filterOrderReport(e)}>{t('page_dashboard.card.processing_attack.title')}</MenuItem>
                          <MenuItem onClick={(e) => filterOrderReport(e)}>{t('page_dashboard.card.completed_attack.title')}</MenuItem>
                          <MenuItem onClick={(e) => filterOrderReport(e)}>{t('page_dashboard.card.today_attack.title')}</MenuItem>
                          <MenuItem onClick={(e) => filterOrderReport(e)}>{t('page_dashboard.card.weekly_attack.title')}</MenuItem>
                          <MenuItem onClick={(e) => filterOrderReport(e)}>{t('page_dashboard.card.retry_attack.title')}</MenuItem>
                        </MenuList>
                      </Menu> */}
                    </WrapItem>
                  </Wrap>
                </form>
              </Box>
              <Box display="flex" justifyContent="space-between" py={4} px={6} alignItems="center">
                <Heading as={"h4"} color={"gray.400"} size={"sm"}>
                  {t('page_attack_history.attack_list_card.title')}
                </Heading>
                {histories.length < page.totalData && 
                  <Box display="flex" alignItems="center">
                    <Button variant="ghost" onClick={() => handlePrevPage()}><FaAngleLeft size={20}/></Button>
                    <Text mx={5}>{page.onPage} / {page.totalPage}</Text>
                    <Button variant="ghost" onClick={() => handleNextPage()}><FaAngleRight size={20}/></Button>
                  </Box>
                }
              </Box>
              <Box style={{height: '80vh'}} px={6} overflowY="scroll">
                {histories.map((history, idx) => (
                  <Skeleton isLoaded={!isLoading}>
                    <Card mb={6} variant="filled" size="sm" key={idx}>
                      <CardBody bg='teal.400' borderRadius={10} display="flex" justifyContent="space-between" alignItems="center" px={6} _hover={{cursor: 'pointer', bg: listColorScheme.background, transition: '0.2s'}} className="list-attack-history" id={`list-attack-history-${history.id}`} onClick={() => handleDetailHistory(history)}>
                        <Box>
                          <Heading size='sm' mb={2}>{history.username}</Heading>
                          <Text fontSize={13}>{dateTimeFormat(history.created_at)}</Text>
                        </Box>
                        <Box textAlign="right" display="flex" flexDir="column" alignItems="end">
                          <Box display="flex" alignItems="center">
                            <Text fontWeight="medium" mr={2}>{history.service_group}</Text>
                            <FaAngleRight />
                          </Box>
                          <Text fontSize={13}>{t('page_attack_history.attack_list_card.attack_list_item.executed')}: <b>{history.executed}</b></Text>
                          <Text fontSize={13}>Total: <b>{history.total}</b></Text>
                        </Box>
                      </CardBody>
                    </Card>
                  </Skeleton>
                ))}
              </Box>
            </Box>
          </VStack>
          <VStack w="full" h="full" spacing={10} py={isMobile ? "4" : "0"} px={isMobile ? "4" : "0"} alignItems="flex-start">
            <ErrorModal isOpen={isOpen} onClose={onClose} msg={errorModalMsg} />
            <TableContainer bg="gray.700" w="full" borderRadius={4}>
              {Object.keys(detailHistory).length > 0 ? 
                <Box>
                  <Box p={5} display="flex" justifyContent="space-between" alignItems="center">
                    <Text fontWeight="bold" fontSize={20}>{detailHistory.username}</Text>

                    {handleStatus(detailHistory.status)}
                    
                    <Box>
                      { detailHistory.status === 'stopped' && cookieCutter.get('role') !== ROLE_ADMIN ?
                        <>
                          <Button colorScheme="teal" onClick={() => handleRetryOrder(detailHistory)} variant="outline" mr="3">Retry</Button>
                          <Button colorScheme="red" onClick={() => handleViewError(detailHistory.order_id)} variant="outline" mr="3">View Error</Button>
                        </> : ''
                      }
                      <Button colorScheme="teal" as="a" target="_blank" href={`${process.env.REACT_APP_SPREADSHEET_BASE_URL}/${detailHistory.sheet_id}`}>{t('page_attack_history.detail_attack_card.download')}</Button>
                    </Box>
                  </Box>
                  <Box margin={3}>
                    <Input type="text" placeholder="search..." onChange={(e) => searchOrderReport(e)}/>
                  </Box>
                  <Box style={{height: '85vh'}} overflow="scroll">
                    <Table variant='striped' colorScheme='teal'>
                      <Thead>
                        <Tr>
                          <Th>{t('page_attack_history.table.attack_id')}</Th>
                          <Th>{t('page_attack_history.table.identifier')}</Th>
                          <Th>{t('page_attack_history.table.service')}</Th>
                          <Th>{t('page_attack_history.table.target')}</Th>
                        </Tr>
                      </Thead>
                      {detailHistory.isFilter ?
                        <Tbody>
                          {detailHistory.orderReportFilter.map((data, idx) => (
                            <Tr key={idx} whiteSpace="normal" wordBreak="break-word" overflowWrap="break-word">
                              <Td>{data.id}</Td>
                              <Td>{data.identifier}</Td>
                              <Td>{data.service_name}</Td>
                              <Td>{data.target}</Td>
                            </Tr>
                          ))}
                        </Tbody>
                        :
                        <Tbody>
                          {detailHistory.orderReport.map((data, idx) => (
                            <Tr key={idx} whiteSpace="normal" wordBreak="break-word" overflowWrap="break-word">
                              <Td>{data.id}</Td>
                              <Td>{data.identifier}</Td>
                              <Td>{data.service_name}</Td>
                              <Td>{data.target}</Td>
                            </Tr>
                          ))}
                        </Tbody>
                      }
                    </Table>
                  </Box>
                </Box>
                :
                <Box display="flex" justifyContent="center" alignItems="center" h="full" style={{height: '98vh'}}>
                  <Heading>{t('page_attack_history.detail_attack_card.empty')}</Heading>
                </Box>
              }
            </TableContainer>
          </VStack>
        </Flex>
      </Container>
    </>
  );
}

export default AttackHistory