import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Badge,
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
  useToast,
  Text,
  Link,
  Icon,
  useBreakpointValue,
} from "@chakra-ui/react";
import Helmet from "react-helmet";
import { useEffect, useState } from "react";
import http from "../utils/Http";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { HiOutlineExternalLink } from "react-icons/hi";

export default function Monitoring() {
  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const [isLoading, setIsLoading] = useState(false);
  const [monitoring, setMonitoring] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [t] = useTranslation("global");
  const [formValues, setFormValues] = useState({
    username: "",
    group: "",
    data: "",
  });
  const [page, setPage] = useState({
    onPage: 1,
    totalData: 0,
    totalPage: 0,
    isPagging: true,
  });

  // const handleFormChange = (e) => {
  //   setFormValues({ ...formValues, [e.target.name]: e.target.value });
  // };

  // const handleSubmit = async () => {
  //   try {
  //     const res = await http.post("/monitor", formValues);
  //     toast({
  //       title: "Monitor Created!",
  //       description: "Your monitor successfully added.",
  //       status: "success",
  //       duration: 9000,
  //       isClosable: true,
  //     });
  //     onClose();
  //   } catch (error) {
  //     // Handle error
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    getMonitoring();
  }, [page.onPage]);

  const getMonitoring = async () => {
    setIsLoading(true);
    const response = await http.get(`/monitor?page=${page.onPage}`);
    setMonitoring(response.data.data.results);
    setPage((curr) => ({
      ...curr,
      totalData: response.data.data.total,
      totalPage: Math.ceil(response.data.data.total / 10),
    }));
    setIsLoading(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filterData = (data) => {
    const values = Object.values(data).join(" ").toLowerCase();
    return values.includes(searchTerm.toLowerCase());
  };

  const handleNextPage = () => {
    if (page.onPage < page.totalPage) {
      setPage((curr) => ({
        onPage: curr.onPage + 1,
        totalData: curr.totalData,
        totalPage: curr.totalPage,
      }));
    }
  };

  const handlePrevPage = () => {
    if (page.onPage !== 1) {
      setPage((curr) => ({
        onPage: curr.onPage - 1,
        totalData: curr.totalData,
        totalPage: curr.totalPage,
      }));
    }
  };

  const filteredData = Object.values(monitoring).filter(filterData);

  function addPlatformDomain(group, url) {
    const groupDomains = {
      instagram: "https://instagram.com/",
      tiktok: "https://tiktok.com/",
    };

    if (url.startsWith("http://") || url.startsWith("https://")) {
      return url;
    } else {
      return groupDomains[group] + url;
    }
  }

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} / {t("page_monitoring.title")}
        </title>
      </Helmet>
      <Container maxW="container.xl" p={0}>
        <Heading py={8} px={isMobile ? "4" : "0"} color={"gray.400"}>
          {t("page_monitoring.title")}
        </Heading>
        <VStack
          w="full"
          h="full"
          px={isMobile ? "4" : "0"}
          pb={isMobile ? "4" : "0"}
          spacing={10}
          alignItems="flex-start"
        >
          <Flex maxW={isMobile ? "full" : "2xl"}>
            <VStack
              w="100%"
              h="full"
              spacing={10}
              pr={8}
              alignItems="flex-start"
            >
              <Input
                type="text"
                onChange={handleSearch}
                placeholder={t("page_monitoring.search")}
              />
            </VStack>
          </Flex>
          {/* <FormControl></FormControl> */}
          <TableContainer
            bg="gray.700"
            w="full"
            h="full"
            borderRadius={4}
            mb={10}
          >
            {filteredData.length > 0 ? (
              <Box>
                <Table variant="striped" colorScheme="teal">
                  <TableCaption>
                    <Box display="flex" justifyContent="space-between">
                      <Button variant="ghost" onClick={() => handlePrevPage()}>
                        <FaAngleLeft size={20} />
                      </Button>
                      <Text mx={5}>
                        {page.onPage} / {page.totalPage}
                      </Text>
                      <Button variant="ghost" onClick={() => handleNextPage()}>
                        <FaAngleRight size={20} />
                      </Button>
                    </Box>
                  </TableCaption>
                  <Thead>
                    <Tr>
                      <Th maxW={20}>{t("page_monitoring.table.attack_id")}</Th>
                      <Th>{t("page_monitoring.table.identifier")}</Th>
                      <Th>{t("page_monitoring.table.service")}</Th>
                      <Th>{t("page_monitoring.table.target")}</Th>
                      <Th>{t("page_monitoring.table.last_checked")}</Th>
                      <Th>Status</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredData.map((data, idx) => (
                      <Tr key={idx} whiteSpace="normal" wordBreak="break-word" overflowWrap="break-word">
                        <Td>{data.id}</Td>
                        <Td>{data.username}</Td>
                        <Td>{data.group}</Td>
                        <Td>
                          <Text noOfLines={1} maxWidth={400}>
                            <Link
                              href={addPlatformDomain(data.group, data.data)}
                              isExternal
                            >
                              <Flex align="center" gap={1}>
                                {data.data}
                                <Icon as={HiOutlineExternalLink} mx="2px" />
                              </Flex>
                            </Link>
                          </Text>
                        </Td>
                        <Td>
                          <Text noOfLines={1}>
                            {data.updated_at &&
                              data.updated_at.replace("T", " ").slice(0, 19)}
                          </Text>
                        </Td>
                        <Td>
                          {data.status === "active" ? (
                            <Badge colorScheme="green">{data.status}</Badge>
                          ) : (
                            <Badge colorScheme="red">{data.status}</Badge>
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                h="full"
              >
                <Heading>Empty History</Heading>
              </Box>
            )}
          </TableContainer>
        </VStack>
        {/* <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Monitor</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <FormLabel>Group</FormLabel>
                <Select
                  name="group"
                  value={formValues.group}
                  onChange={handleFormChange}
                >
                  <option value="">Select Group</option>
                  <option value="tiktok">TikTok</option>
                  <option value="twitter">Twitter</option>
                  <option value="youtube">YouTube</option>
                  <option value="facebook">Facebook</option>
                  <option value="instagram">Instagram</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel mt={5}>Data</FormLabel>
                <Input
                  type="text"
                  name="data"
                  value={formValues.data}
                  onChange={handleFormChange}
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="teal" onClick={handleSubmit}>
                Add
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal> */}
      </Container>
    </>
  );
}
