import {
  Avatar,
  Center,
  Container,
  Grid,
  GridItem,
  Heading,
  Text,
  Box,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {useHistory} from "react-router-dom";
import http from "../utils/Http";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import Chart from "../components/Chart";

export default function Dashboard() {
  const isDesktop = useBreakpointValue({ base: false, md: true });

  const [user, setUser] = useState([]);
  const [t] = useTranslation('global')
  const [stats, setStats] = useState([]);
  const [chartStats, setChartStats] = useState({});
  const history = useHistory()

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [servicesRes, statsRes, chartStatsRes] = await Promise.all([
        http.get("/auth/verify"),
        http.get("/statistics/summary"),
        http.get("/statistics"),
      ]);

      console.log(servicesRes.data);
      setUser(servicesRes.data.data);

      console.log(statsRes.data);
      setStats(statsRes.data.data);

      console.log(chartStatsRes.data);
      setChartStats(chartStatsRes.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setChartStats({});
    }
  };

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} / {t('page_dashboard.title')}</title>
      </Helmet>
      <Container maxW="container.xl" p={0} px={4}>
        <Heading py={8} color={"gray.400"}>
          {t('page_dashboard.title')}
        </Heading>
        {isDesktop ? (
          <>
            <Grid templateColumns="repeat(4, 1fr)" gap={6} py={8}>
              <GridItem
                w="100%"
                h="20"
                bg="gray.700"
                borderRadius={4}
                py={4}
                px={4}
              >
                <Heading as={"h4"} size={"xs"} color={"gray.400"}>
                  {t('page_dashboard.card.total_quota.title')}
                </Heading>
                <Heading as={"h4"} size={"md"} color={"gray.400"} py={2}>
                  {stats.credit}
                </Heading>
              </GridItem>
              <GridItem
                w="100%"
                h="20"
                bg="gray.700"
                borderRadius={4}
                py={4}
                px={4}
              >
                <Heading as={"h4"} size={"xs"} color={"gray.400"}>
                  {t('page_dashboard.card.total_bot.title')}
                </Heading>
                <Heading as={"h4"} size={"md"} color={"gray.400"} py={2}>
                  {stats.total_bot}
                </Heading>
              </GridItem>
              <GridItem
                w="100%"
                h="20"
                bg="gray.700"
                borderRadius={4}
                py={4}
                px={4}
              >
                <Heading as={"h4"} size={"xs"} color={"gray.400"}>
                  {t('page_dashboard.card.total_active.title')}
                </Heading>
                <Heading as={"h4"} size={"md"} color={"gray.400"} py={2}>
                  {stats.active_bot}
                </Heading>
              </GridItem>
              <GridItem
                w="100%"
                h="20"
                bg="gray.700"
                borderRadius={4}
                py={4}
                px={4}
              >
                <Heading as={"h4"} size={"xs"} color={"gray.400"}>
                  {t('page_dashboard.card.success_attack.title')}
                </Heading>
                <Heading as={"h4"} size={"md"} color={"gray.400"} py={2}>
                  {stats.success_attack}
                </Heading>
              </GridItem>


              <GridItem
                w="100%"
                h="20"
                bg="gray.700"
                borderRadius={4}
                py={4}
                px={4}
                cursor="pointer"
                onClick={() => history.push(`/attack-history?filter=${t('page_dashboard.card.processing_attack.title')}`)}
              >
                <Heading as={"h4"} size={"xs"} color={"gray.400"}>
                  {t('page_dashboard.card.processing_attack.title')}
                </Heading>
                <Heading as={"h4"} size={"md"} color={"gray.400"} py={2}>
                  {stats.processing_attack}
                </Heading>
              </GridItem>
              <GridItem
                w="100%"
                h="20"
                bg="gray.700"
                borderRadius={4}
                py={4}
                px={4}
                cursor="pointer"
                onClick={() => history.push(`/attack-history?filter=${t('page_dashboard.card.completed_attack.title')}`)}
              >
                <Heading as={"h4"} size={"xs"} color={"gray.400"}>
                  {t('page_dashboard.card.completed_attack.title')}
                </Heading>
                <Heading as={"h4"} size={"md"} color={"gray.400"} py={2}>
                  {stats.completed_attack}
                </Heading>
              </GridItem>
              <GridItem
                w="100%"
                h="20"
                bg="gray.700"
                borderRadius={4}
                py={4}
                px={4}
                cursor="pointer"
                onClick={() => history.push(`/attack-history?filter=${t('page_dashboard.card.today_attack.title')}`)}
              >
                <Heading as={"h4"} size={"xs"} color={"gray.400"}>
                  {t('page_dashboard.card.today_attack.title')}
                </Heading>
                <Heading as={"h4"} size={"md"} color={"gray.400"} py={2}>
                  {stats.today_attack}
                </Heading>
              </GridItem>
              <GridItem
                w="100%"
                h="20"
                bg="gray.700"
                borderRadius={4}
                py={4}
                px={4}
                cursor="pointer"
                onClick={() => history.push(`/attack-history?filter=${t('page_dashboard.card.weekly_attack.title')}`)}
              >
                <Heading as={"h4"} size={"xs"} color={"gray.400"}>
                  {t('page_dashboard.card.weekly_attack.title')}
                </Heading>
                <Heading as={"h4"} size={"md"} color={"gray.400"} py={2}>
                  {stats.weekly_attack}
                </Heading>
              </GridItem>
            </Grid>
            <Box
              w="100%"
              h="20"
              bg="gray.700"
              borderRadius={4}
              py={4}
              px={4}
              mb={8}
              cursor="pointer"
              onClick={() => history.push(`/attack-history?filter=${t('page_dashboard.card.retry_attack.title')}`)}
            >
              <Heading as={"h4"} size={"xs"} color={"gray.400"}>
                {t('page_dashboard.card.retry_attack.title')}
              </Heading>
              <Heading as={"h4"} size={"md"} color={"gray.400"} py={2}>
                {stats.retry_attack}
              </Heading>
            </Box>
          </>
        ) : (
          <Flex flexWrap="wrap" gap={4} py={4}>
            <Box
              w="100%"
              minH="20"
              bg="gray.700"
              borderRadius={4}
              py={4}
              px={4}
            >
              <Heading as="h4" size="xs" mb={2}>
                {t('page_dashboard.card.total_quota.title')}
              </Heading>
              <Heading as="h4" size="md">
                {stats.credit}
              </Heading>
            </Box>
            <Box
              w="100%"
              minH="20"
              bg="gray.700"
              borderRadius={4}
              py={4}
              px={4}
            >
              <Heading as="h4" size="xs" mb={2}>
                {t('page_dashboard.card.total_bot.title')}
              </Heading>
              <Heading as="h4" size="md">
                {stats.total_bot}
              </Heading>
            </Box>
            <Box
              w="100%"
              minH="20"
              bg="gray.700"
              borderRadius={4}
              py={4}
              px={4}
            >
              <Heading as="h4" size="xs" mb={2}>
                {t('page_dashboard.card.active_bot.title')}
              </Heading>
              <Heading as="h4" size="md">
                {stats.active_bot}
              </Heading>
            </Box>
            <Box
              w="100%"
              minH="20"
              bg="gray.700"
              borderRadius={4}
              py={4}
              px={4}
            >
              <Heading as="h4" size="xs" mb={2}>
                {t('page_dashboard.card.success_attack.title')}
              </Heading>
              <Heading as="h4" size="md">
                {stats.success_attack}
              </Heading>
            </Box>

            <Box
              w="100%"
              minH="20"
              bg="gray.700"
              borderRadius={4}
              py={4}
              px={4}
            >
              <Heading as="h4" size="xs" mb={2}>
                {t('page_dashboard.card.processing_attack.title')}
              </Heading>
              <Heading as="h4" size="md">
                {stats.processing_attack}
              </Heading>
            </Box>
            <Box
              w="100%"
              minH="20"
              bg="gray.700"
              borderRadius={4}
              py={4}
              px={4}
            >
              <Heading as="h4" size="xs" mb={2}>
                {t('page_dashboard.card.completed_attack.title')}
              </Heading>
              <Heading as="h4" size="md">
                {stats.completed_attack}
              </Heading>
            </Box>
            <Box
              w="100%"
              minH="20"
              bg="gray.700"
              borderRadius={4}
              py={4}
              px={4}
            >
              <Heading as="h4" size="xs" mb={2}>
                {t('page_dashboard.card.today_attack.title')}
              </Heading>
              <Heading as="h4" size="md">
                {stats.today_attack}
              </Heading>
            </Box>
            <Box
              w="100%"
              minH="20"
              bg="gray.700"
              borderRadius={4}
              py={4}
              px={4}
            >
              <Heading as="h4" size="xs" mb={2}>
                {t('page_dashboard.card.weekly_attack.title')}
              </Heading>
              <Heading as="h4" size="md">
                {stats.weekly_attack}
              </Heading>
            </Box>
          </Flex>
        )}
        {isDesktop ? (
          <Grid templateColumns="repeat(4, 1fr)" gap={6}>
            <GridItem colSpan={3} bg="gray.700" borderRadius={4}>
              <Center h="100%">
                <Box w="full">
                  <Chart data={chartStats} />
                </Box>
              </Center>
            </GridItem>

            <GridItem
              h="sm"
              bg="gray.700"
              borderRadius={4}
              py={4}
              px={4}
              colSpan={1}
            >
              <Heading as="h4" size="xs" color="gray.400">
                {t('page_dashboard.card.account_information.title')}
              </Heading>
              <Center mt={8}>
                <Avatar
                  size="2xl"
                  name="Christian Nwamba"
                  src="https://bit.ly/code-beast"
                />
              </Center>
              <Center mt={4}>
                <Text fontSize="xl">{user.fullname}</Text>
              </Center>
            </GridItem>
          </Grid>
        ) : (
          <Flex direction="column" gap={4} py={4}>
            <Box
              flex="3"
              w="100%" // Ensure the box takes the full width on mobile
              bg="gray.700"
              borderRadius={4}
            >
              <Center h="100%">
                <Box w="full">
                  <Chart data={chartStats} />
                </Box>
              </Center>
            </Box>
            <Box flex="1" minH="sm" bg="gray.700" borderRadius={4} py={4}>
              <Heading as="h2" size="xs" color="gray.400" mb={4} px={4}>
                Account Information
              </Heading>
              <Center>
                <Avatar
                  size="2xl"
                  name="Christian Nwamba"
                  src="https://bit.ly/code-beast"
                />
              </Center>
              <Center mt={4}>
                <Text fontSize="xl">{user.fullname}</Text>
              </Center>
            </Box>
          </Flex>
        )}
      </Container>
    </>
  );
}
