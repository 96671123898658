import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react'
import { I18nextProvider, initReactI18next } from "react-i18next";
import global_en from './translations/en/global.json'
import global_id from './translations/id/global.json'
import i18next from 'i18next';

i18next.init({
  resources: {
    en: { global: global_en },
    id: { global: global_id }
  },
  lng: 'en',
  interpolation: {
    escapeValue: false
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ChakraProvider>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
