import Axios from "axios";
import cookieCutter from 'cookie-cutter'

const http = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const accessToken = cookieCutter.get('access_token')
if(accessToken){
  http.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
}

export default http;
