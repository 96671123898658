import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AttackHub from "./pages/AttackHub";
import Dashboard from "./pages/Dashboard";
import AttackHistory from "./pages/AttackHistory";
import Login from "./pages/Login";
import Monitoring from "./pages/Monitoring";
import PrivatePage from "./utils/PrivatePage";
import ManageAccount from "./pages/ManageAccount";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <PrivatePage>
          <Route exact path="/">
            <Redirect to="dashboard" />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/attack-panel">
            <AttackHub />
          </Route>
          <Route path="/attack-history">
            <AttackHistory />
          </Route>
          <Route path="/monitoring">
            <Monitoring />
          </Route>
          <Route path="/manage-account">
            <ManageAccount />
          </Route>
        </PrivatePage>
      </Switch>
    </Router>
  );
}

export default App;
