import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import cookieCutter from "cookie-cutter";
import { useHistory } from 'react-router-dom';
import { ROLE_ADMIN } from './Const';

const PrivatePage = ({children}) => {
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    
    useEffect(() => {
        setIsLoading(true)
        const accessToken = cookieCutter.get("access_token");
        const fullname = cookieCutter.get("fullname");
        const role = cookieCutter.get("role");

        // auth middleware
        if (typeof accessToken === "undefined" || accessToken.length < 2){
            history.push(`/login`);
        } else if(typeof fullname === "undefined" || fullname.length < 2) {
            history.push(`/login`);
        }else if(typeof role === "undefined" || role.length < 2){
            history.push(`/login`);
        }

        // admin middleware route
        if(history.location.pathname == '/manage-account'){
            if(cookieCutter.get('role') !== ROLE_ADMIN) history.push('/dashboard')
        }

        setIsLoading(false)
    }, [])

    if(!isLoading){
        return (
            <div>
                <Navbar />
                {children}
            </div>
        )
    }else{
        return (<></>)
    }
}

export default PrivatePage