import React, { useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Badge,
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
  useToast,
  Text,
  Textarea,
  useBreakpointValue
} from "@chakra-ui/react";
import Helmet from "react-helmet";
import { useEffect, useState } from "react";
import http from "../utils/Http";
import {
  FaAngleLeft,
  FaAngleRight,
  FaEdit,
  FaPlus,
  FaTrash,
} from "react-icons/fa";
import {
  STATUS_ACCOUNT_ACTIVE,
  STATUS_ACCOUNT_INACTIVE,
  STATUS_ACCOUNT_VERIFY,
} from "../utils/Const";

const ModalEdit = ({ isOpen, onClose, data, group, updateAccount }) => {
  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update {group} account</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <form onSubmit={(e) => updateAccount(e)} id="edit-account-form">
            <Input type="hidden" name="id" defaultValue={data.id} />

            {data.hasOwnProperty("email") && (
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  placeholder="Email"
                  name="email"
                  defaultValue={data.email}
                />
              </FormControl>
            )}

            <FormControl mt={4}>
              <FormLabel>Username</FormLabel>
              <Input
                placeholder="Username"
                name="username"
                defaultValue={data.username}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Password</FormLabel>
              <Input
                placeholder="Password"
                name="password"
                defaultValue={data.password}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Status</FormLabel>
              <Select name="status" defaultValue={data.status}>
                <option value={STATUS_ACCOUNT_ACTIVE}>
                  {STATUS_ACCOUNT_ACTIVE}
                </option>
                <option value={STATUS_ACCOUNT_INACTIVE}>
                  {STATUS_ACCOUNT_INACTIVE}
                </option>
                <option value={STATUS_ACCOUNT_VERIFY}>
                  {STATUS_ACCOUNT_VERIFY}
                </option>
              </Select>
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Session</FormLabel>
              <Textarea name="session" defaultValue={data.session} />
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            type="submit"
            colorScheme="teal"
            mr={3}
            form="edit-account-form"
          >
            Save
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ModalCreate = ({ isOpen, onClose, group, createAccount }) => {
  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create {group} account</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <form onSubmit={(e) => createAccount(e)} id="edit-account-form">
            <FormControl mt={4}>
              <FormLabel>Service</FormLabel>
              <Input defaultValue={group} disabled />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Account</FormLabel>
              <Textarea
                name="account"
                placeholder={
                  group === "youtube"
                    ? `username,password,email(optional)
username,password,email(optional)
…`
                    : `username,password
username,password
…`
                }
              />
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            type="submit"
            colorScheme="teal"
            mr={3}
            form="edit-account-form"
          >
            Save
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ManageAccount = () => {
  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [detailAccount, setDetailAccount] = useState({});
  const [page, setPage] = useState({
    onPage: 1,
    totalData: 0,
    totalPage: 0,
  });
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isCreateOpen,
    onOpen: onCreateOpen,
    onClose: onCreateClose,
  } = useDisclosure();

  const fetchServices = async () => {
    const response = await http.get("/service");
    const filteredServices = response.data.data.results.filter(
      (service) => service.group !== "tiktok"
    );
    let newFilter = filteredServices.map((item) => item.group);
    newFilter = newFilter.filter(
      (value, index, array) => array.indexOf(value) === index
    );

    setServices(newFilter);
    setSelectedService(newFilter[0]);
  };

  const getAccounts = async () => {
    const response = await http.get(
      `/account/${selectedService}?page=${page.onPage}`
    );
    const totalPage = Math.ceil(response.data.data.total / 10);
    setAccounts(response.data.data.results);
    setPage((curr) => ({
      onPage: totalPage < curr.onPage ? 1 : curr.onPage,
      totalData: response.data.data.total,
      totalPage: totalPage,
    }));
  };

  const getDetailAccount = async (account_id) => {
    const response = await http.get(
      `/account/${selectedService}/${account_id}`
    );
    setDetailAccount(response.data.data);
  };

  const createAccount = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    try {
      await http.post(`/account/${selectedService}`, {
        account: formData.get("account"),
      });
      await getAccounts();

      onCreateClose();
      toast({
        title: "Create Account was success",
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Create Account was fails",
        status: "error",
        isClosable: true,
      });
    }
  };

  const updateAccount = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    try {
      // check is valid json
      if (formData.get("session")) {
        try {
          JSON.parse(formData.get("session"));
        } catch (error) {
          return toast({
            title: "Session value input have a invalid json",
            status: "error",
            isClosable: true,
          });
        }
      }

      let payload = {
        username: formData.get("username"),
        password: formData.get("password"),
        session:
          formData.get("session") === "" ? null : formData.get("session"),
        status: formData.get("status"),
      };
      if (formData.get("email")) payload.email = formData.get("email");

      await http.patch(
        `/account/${selectedService}/${formData.get("id")}`,
        payload
      );
      await getAccounts();

      setDetailAccount({});
      onEditClose();
      toast({
        title: "Update Account was success",
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Update Account was fails",
        status: "error",
        isClosable: true,
      });
    }
  };

  const deleteAccount = async (account_id) => {
    if (window.confirm("You sure?")) {
      try {
        const response = await http.delete(
          `/account/${selectedService}/${account_id}`
        );
        if (response.status === 200) {
          await getAccounts();
          toast({
            title: "Delete Account was success",
            status: "success",
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Delete Account was fails",
          status: "error",
          isClosable: true,
        });
      }
    }
  };

  const handleNextPage = () => {
    if (page.onPage < page.totalPage) {
      setPage((curr) => ({
        onPage: curr.onPage + 1,
        totalData: curr.totalData,
        totalPage: curr.totalPage,
      }));
    }
  };

  const handlePrevPage = () => {
    if (page.onPage !== 1) {
      setPage((curr) => ({
        onPage: curr.onPage - 1,
        totalData: curr.totalData,
        totalPage: curr.totalPage,
      }));
    }
  };

  useEffect(() => {
    fetchServices();
  }, []);

  useEffect(() => {
    if (selectedService) {
      getAccounts();
    }
  }, [selectedService, page.onPage]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} / Manage Account</title>
      </Helmet>

      <ModalEdit
        isOpen={isEditOpen}
        onClose={onEditClose}
        data={detailAccount}
        group={selectedService}
        updateAccount={updateAccount}
      />

      <ModalCreate
        isOpen={isCreateOpen}
        onClose={onCreateClose}
        group={selectedService}
        createAccount={createAccount}
      />

      <Container maxW="container.xl" p={0}>
        <Heading py={8} px={isMobile ? "4" : "0"} color={"gray.400"}>
          Manage Account
        </Heading>
        <VStack w="full" h="full" px={isMobile ? "4" : "0"} pb={isMobile ? "4" : "0"} spacing={10} alignItems="flex-start">
          <Flex w="full" justifyContent="space-between" gap={4}>
            <Box w="85%">
              <Select onChange={(e) => setSelectedService(e.target.value)}>
                {services.map((service, idx) => (
                  <option key={idx} value={service}>
                    {service}
                  </option>
                ))}
              </Select>
            </Box>
            <Box>
              <Button leftIcon={<FaPlus />} onClick={onCreateOpen}>
                Add Account
              </Button>
            </Box>
          </Flex>
          <TableContainer
            bg="gray.700"
            w="full"
            h="full"
            borderRadius={4}
            mb={10}
          >
            <Box>
              <Table variant="striped" colorScheme="teal">
                <TableCaption>
                  <Box display="flex" justifyContent="space-between">
                    <Button variant="ghost" onClick={() => handlePrevPage()}>
                      <FaAngleLeft size={20} />
                    </Button>
                    <Text mx={5}>
                      {page.onPage} / {page.totalPage}
                    </Text>
                    <Button variant="ghost" onClick={() => handleNextPage()}>
                      <FaAngleRight size={20} />
                    </Button>
                  </Box>
                </TableCaption>
                <Thead>
                  <Tr>
                    <Th>Account ID</Th>
                    <Th>Username</Th>
                    <Th>Password</Th>
                    <Th>Status</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {accounts.map((account, idx) => (
                    <Tr key={idx} data>
                      <Td>{account.id}</Td>
                      <Td>{account.username}</Td>
                      <Td>{account.password}</Td>
                      <Td>{account.status}</Td>
                      <Td>
                        <Button
                          variant="ghost"
                          colorScheme="gray"
                          onClick={async () => {
                            await getDetailAccount(account.id);
                            onEditOpen();
                          }}
                        >
                          <FaEdit />
                        </Button>
                        <Button
                          variant="ghost"
                          colorScheme="gray"
                          onClick={() => deleteAccount(account.id)}
                        >
                          <FaTrash />
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </TableContainer>
        </VStack>
      </Container>
    </>
  );
};

export default ManageAccount;
