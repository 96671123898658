import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    Button,
    useColorModeValue,
    useToast
} from '@chakra-ui/react';
import { useState } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import bottifyLogo from '../assets/logo/bottify.png'
import http from '../utils/Http';
import cookieCutter from "cookie-cutter";
import { useHistory } from 'react-router-dom';
import Helmet from 'react-helmet';
  
export default function Login() {
const [showPassword, setShowPassword] = useState(false);
const [isLoading, setIsLoading] = useState(false);
const history = useHistory()
const toast = useToast()

const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    const formData = new FormData(e.target)

    try {
        const response = await http.post('/auth/login', {
            email: formData.get('email'),
            password: formData.get('password')
        })
        const date = new Date();
        const expDate = new Date(date.setMonth(date.getMonth() + 8));
        const apiKey = response.data.data.apiKey;

        await cookieCutter.set("access_token", apiKey, { expires: expDate });
        await cookieCutter.set("fullname", response.data.data.fullname, { expires: expDate });
        await cookieCutter.set("role", response.data.data.role, { expires: expDate });
        http.defaults.headers.common["Authorization"] = `Bearer ${apiKey}`;

        history.push("/");
    } catch (error) {
        toast({
            title: error.response.data.errors,
            description: error.response.data.message,
            status: 'error',
            isClosable: true,
        })
    }
    
    setIsLoading(false)
}

return (
    <>
        <Helmet>
            <title>{process.env.REACT_APP_NAME} / Login</title>
        </Helmet>
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}
        >
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Stack align={'center'} px={20}>
                <img src={bottifyLogo} alt="logo"/>
                </Stack>
                <Box
                rounded={'lg'}
                bg={useColorModeValue('white', 'gray.700')}
                boxShadow={'lg'}
                p={8}>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Stack spacing={4}>
                        <FormControl id="email" isRequired>
                            <FormLabel>Email</FormLabel>
                            <Input type="text" name='email'/>
                        </FormControl>
                        <FormControl id="password" isRequired>
                            <FormLabel>Password</FormLabel>
                            <InputGroup>
                                <Input type={showPassword ? 'text' : 'password'} name='password'/>
                                <InputRightElement h={'full'}>
                                <Button
                                    variant={'ghost'}
                                    onClick={() =>
                                    setShowPassword((showPassword) => !showPassword)
                                    }>
                                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                </Button>
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        <Stack spacing={10} pt={2}>
                            <Button colorScheme="gray" type='submit' isLoading={isLoading}>
                                Sign in
                            </Button>
                        </Stack>
                    </Stack>
                </form>
                </Box>
            </Stack>
        </Flex>
    </>
);
}