const dateTimeFormat = (iso8601) => {
    const date = new Date(iso8601);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const timeOptions = { hour: 'numeric', minute: 'numeric' };

    const localizedDateString = date.toLocaleDateString('id-ID', options);
    const localizedTimeString = date.toLocaleTimeString('id-ID', timeOptions);
    
    return `${localizedDateString} ${localizedTimeString}`;
}

export {
    dateTimeFormat
}